.copy-url {
  &-icon {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 24px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  &-icon:hover {
    transform: scale(1.1);
  }
}
