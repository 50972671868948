.header
{
    background-color: #000E24;
    font-size: 14px;

    &-container
    {
        width: 80%;
        max-width: 100%;
        height: 100px;
        margin: 0 auto;
        overflow: hidden;
    }
    &-logo {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;

        .logo
        {
            max-width: 100%;
            width: 200px;
            height: auto;
            margin-right: 16px;
        }

    }
    &-links
    {
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
        gap: 8px;
        &-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            grid-gap: 4px;
            gap: 4px;
            justify-content: center;
            height: 97px;
            opacity: 0.5;
            cursor: pointer;
            img {
                height: auto;
                width: 32px;
            }
            a:link, a:visited
            {
                display: inline-block;
                padding: .5em 1.5em;
                color: #F5F5F5;
                text-decoration: none;
            }
        }
    }
    &-search
    {
        width: 180px;
        height: 40px;
        line-height: 40px;
        border: 1px solid #FFFFFF;
        border-radius: 24px;
        padding: 0px 16px;
        background-color: rgba(0,61,102,0.4);
        color: #FFFFFF;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;

        input
        {
            outline: 0;
            border-width: 0 0 0px;
            background-color: transparent;
            border-color: #FFFFFF;
            color: #FFFFFF;
        }
        ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
            color:    #FFFFFF;
        }
        :-moz-placeholder { /* Mozilla Firefox 4 to 18 */
            color:    #FFFFFF;
            opacity:  1;
        }
        ::-moz-placeholder { /* Mozilla Firefox 19+ */
            color:    #FFFFFF;
            opacity:  1;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color:    #FFFFFF;
        }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color:    #FFFFFF;
        }

        ::placeholder { /* Most modern browsers support this now. */
            color:    #FFFFFF;
        }
    }
    &-search-hide {
        visibility: hidden;
    }
}
.active-item
{
    color: #FFFFFF;
    border-bottom: 3px solid #004CA4;
    height: 97px;
    opacity: 1;
}
.header, .header-container
{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}
a:link, a:visited, a:hover img
{
    padding: 0;
}

@media (min-width: 960px)
{
    .header, .header-container
    {
        justify-content: space-between;
    }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px)
{

}

/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px)
{

}

/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) 
{
    .header {
      &-search {
        width: 15%;
      }
      &-container
      {
          width: 85%;
      }
    }
}

/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
{
    .header {
        &-container
        {
            height: 100%;
            padding: 16px 16px 0px 16px;
        }
        &-search {
            width: 15%;
        }
    }
}

/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px)
{
    .header {
        &-container
        {
            height: 100%;
            padding: 16px 16px 0px 16px;
        }
        &-logo {
            .logo {
                margin-right: 0;
            }
        }
        &-search {
            display: none;
        }
    }
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px)
{
    .header {
        &-container
        {
            width: 90%;
            height: 100%;
            padding: 16px 16px 0px 16px;
        }
        &-logo {
            .logo {
                margin-right: 0;
            }
        }
        &-search {
            display: none;
        }
        &-links
            {
                gap: 0px;
                font-size: 12px;
                &-item {
                    grid-gap: 2px;
                    gap: 2px;
                    width: 90px;
                    img {
                        width: 24px;
                    }
                    a:link, a:visited
                    {
                        padding: .5em 1em;
                    }
                }
            }
        }
}
