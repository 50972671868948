.login
{
  width: 100vw;
  height: 100vh;
  background: rgb(0,79,131);
  background: linear-gradient(0deg, rgba(0,79,131,1) 0%, rgba(0,61,102,1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  #tsparticles
  {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  &-form
  {
    z-index: 5;
    background-color: #000E24;
    padding: 36px;
    width: 360px;
    -webkit-box-shadow: 0px 3px 26px -2px rgba(10,10,10,0.71);
    box-shadow: 0px 3px 26px -2px rgba(10,10,10,0.71);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .logo
    {
        max-width: 100%;
        width: 12.5rem;
        height: auto;
    }
    form
    {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      label
      {
        height: 2.5rem;
        line-height: 2.5rem;
        border: 1px solid #FFFFFF;
        border-radius: 24px;
        padding: 0 1rem;
        background-color: #FFFFFF;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
      label input
      {
        outline: 0;
        border-width: 0 0 0px;
        padding: 8px;
        border-color: #FFFFFF;
      }
      label input:-webkit-autofill,
      label input:-webkit-autofill:hover,
      label input:-webkit-autofill:focus,
      label input:-webkit-autofill:active
      {
        -webkit-box-shadow: 0 0 0 30px #FFFFFF inset !important;
      }
    }
    button
    {
      display: inline-block;
      border: none;
      padding: 1rem 2rem;
      margin: 0;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      border-radius: 8px;
      text-decoration: none;
      background: #004CA4;
      color: #ffffff;
      line-height: 1;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    button:hover,
    button:focus
    {
      background: #0053ba;
    }
    button:focus
    {
      outline: 1px solid #fff;
      outline-offset: -4px;
    }
    button:active {
      transform: scale(0.99);
    }
    button:focus {
      outline: 0 !important;
    }
  }
  &-btns
  {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  &-error
  {
    color: #ff0033;
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px)
{

}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px)
{

}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px)
{

}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape)
{

}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px)
{

}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px)
{
  .login {
    &-form {
      width: 60%;
    }
  }
}
