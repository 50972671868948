.dashboard {
  #tsparticles {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 100%;
  margin: 0 auto;
  color: #ffffff;
  &-main-carousel {
    width: 100vw;
  }
  &-games {
    width: 80%;
    max-width: 100%;
    padding: 24px 0px 120px 0px;
    margin: 24px auto;
    color: #ffffff;

    &-list-jackpot {
      display: grid;
      grid-template-columns: repeat(auto-fit, 200px);
      grid-auto-rows: auto;
      justify-content: center;
      gap: 2rem 2rem;
    }
    &-list {
      display: grid;
      grid-template-columns: repeat(auto-fit, 200px);
      grid-auto-rows: 180px;
      justify-content: center;
      gap: 2rem 2rem;
    }
  }
  .title {
    margin-bottom: 24px;
  }
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .dashboard {
    &-games {
      width: 100%;
      &-list-jackpot {
        grid-template-columns: repeat(auto-fit, 200px);
        grid-auto-rows: auto;
      }
      &-list {
        grid-template-columns: repeat(auto-fit, 200px);
        grid-auto-rows: auto;
      }
    }
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .dashboard {
    &-main-carousel {
      display: none;
    }
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .dashboard {
    &-main-carousel {
      display: none;
    }
    &-games {
      width: 100%;
      &-list-jackpot {
        grid-template-columns: repeat(auto-fit, 280px);
        grid-auto-rows: auto;
      }
      &-list {
        grid-template-columns: repeat(auto-fit, 280px);
        grid-auto-rows: auto;
      }
    }
  }
}
