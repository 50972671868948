.home {
    &-icon {
      position: fixed;
      top: 8px;
      right: 8px;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 24px;
      transition: all 0.2s ease-in-out;
      z-index: 999;
      cursor: pointer;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -ms-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
    }
    &-icon:hover {
      transform: scale(1.1);
    }
  }

  /*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1024px) {
  .home {
    &-icon {
      top: 30px;
      right: 24px;
    }
  }
}
  