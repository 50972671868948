.log-out
{
    &-icon {
        position: fixed;
        bottom: 68px;
        right: 24px;
        width: 36px;
        height: 36px;
        border-radius: 4px;
        border: 1px solid  crimson;
        background-color: crimson;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #FFFFFF;
        font-size: 24px;
        transition: all .2s ease-in-out;
        z-index: 999;
        cursor: pointer;
    }
    &-icon:hover {
        transform: scale(1.1);
    }
}