.carousel
{
  .slide-1
  {
    height: 300px;
    background-color: #004F83;
  }
  .slide-2
  {
    height: 300px;
    background-color: green;
  }
  .slide-3
  {
    height: 300px;
    background-color: brown;
  }
  .slide-4
  {
    height: 300px;
    background-color: pink;
  }
}
