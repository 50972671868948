.register {
  width: 100vw;
  height: 100vh;
  background: rgb(0, 79, 131);
  background: linear-gradient(
    0deg,
    rgba(0, 79, 131, 1) 0%,
    rgba(0, 61, 102, 1) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-size: 14px;

  #tsparticles {
    position: absolute;
    width: 100vw;
    height: 100vh;
  }

  &-form {
    z-index: 5;
    background-color: #000e24;
    padding: 16px 30px 36px 30px;
    width: 360px;
    -webkit-box-shadow: 0px 3px 26px -2px rgba(10, 10, 10, 0.71);
    box-shadow: 0px 3px 26px -2px rgba(10, 10, 10, 0.71);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    &-head {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: 10px;
      .logo {
        max-width: 100%;
        width: 12.5rem;
        height: auto;
      }
    }

    form {
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1rem;
      label {
        height: 2.5rem;
        line-height: 2.5rem;
        border: 1px solid #ffffff;
        border-radius: 24px;
        padding: 0 1rem;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;
      }
      label input {
        width: 160px;
        outline: 0;
        border-width: 0 0 0px;
        padding: 8px;
        border-color: #ffffff;
      }
      label input:-webkit-autofill,
      label input:-webkit-autofill:hover,
      label input:-webkit-autofill:focus,
      label input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px #ffffff inset !important;
      }
    }
    button {
      display: inline-block;
      border: none;
      padding: 1rem 2rem;
      margin: 0;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      border-radius: 8px;
      text-decoration: none;
      background: #004ca4;
      color: #ffffff;
      line-height: 1;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
    button:hover,
    button:focus {
      background: #0053ba;
    }
    button:focus {
      outline: 1px solid #fff;
      outline-offset: -4px;
    }
    button:active {
      transform: scale(0.99);
    }
    button:focus {
      outline: 0 !important;
    }
  }
  &-btns {
    padding-top: 20px;
  }
  &-error {
    text-align: center;
    color: #ff0033;
  }
  &-title {
    color: #ffffff;
    font-size: 1.5em;
    text-align: center;
    padding-bottom: 10px;
    font-weight: bolder;
  }

  &-disclaimer {
    color: #ffffff;
    text-align: center;
    margin-top: 5px;
  }

  .animate-title {
    background-image: linear-gradient(
      -225deg,
      #ffffff 0%,
      #5c7898 29%,
      #2f63a0 39%,
      #004ca4 50%,
      #2f63a0 67%,
      #5c7898 78%,
      #ffffff 90%,
      #ffffff 100%,
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 10s linear infinite;
    display: inline-block;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .register {
    width: 100%;
    height: 100%;
  }
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) and (orientation: landscape) {
  .register {
    width: 100%;
    height: 100%;
  }
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .register {
    &-form {
      width: 60%;
    }
    &-disclaimer {
      font-size: 12px;
    }
  }
}
