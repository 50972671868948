/* modal styles */
.modal-la-backdrop {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 40;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px);
}

.modal-la-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  margin: auto;
  transition: all 0.2s ease-in-out;
  padding: 5px 5px 0 5px;
  background-color: #000e23;
  border-radius: 8px;
  height: 400px;
}

.modal-la-content .modal-la-header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 230px;
  background-color: #000e23;
  color: black;
  font-weight: bold;
  letter-spacing: 0.6px;
}

.modal-la-header > h1 {
  font-size: 26px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
}

.modal-la-content .modal-la-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #666;
  background-color: #000e23;
  padding: 10px 0 40px 0;
}

.modal-la-content .modal-la-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #161616;
  padding: 5px;
  font-size: 28px;
}

.modal-la-content .modal-la-footer > p {
  padding-right: 20px;
}

.modal-la-button {
  display: inline-block;
  border: none;
  padding: 1rem 2rem;
  margin: 0;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
    rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 8px;
  text-decoration: none;
  background: #004ca4;
  color: #ffffff;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
  font-size: 18px;
  width: 125px;
  user-select: none;
}

.modal-la-button:hover,
.modal-la-button:focus {
  background: #0053ba;
}

.modal-la-button:active {
  transform: scale(0.99);
}


.modal-la-btns {
  display: flex;
  gap: 45px;
}

.modal-la-button-no {
  background-color: #000e23;
  border: 1.5px solid #004ca4;
}

.modal-la-button-no:hover {
  background: #0c203d;
}

.modal-la-button-no:focus {
  animation-name: button-no-selected;
  animation-duration: 3.2s;
  animation-iteration-count: infinite;
}

.modal-la-button-yes:focus {
  animation-name: button-yes-selected;
  animation-duration: 3.2s;
  animation-iteration-count: infinite;
}

@keyframes button-yes-selected {
  0% {background: #004ca4;}
  15% {background: #005ac1;}
  30% {background: #0065d8;}
  45% {background: #006fed;}
  70% {background: #0065d8;}
  85% {background: #005ac1;}
  100% {background: #004391;}
}

@keyframes button-no-selected {
  0% {background: #000e23;}
  15% {background: #001331;}
  30% {background: #001940;}
  45% {background: #001d4b;}
  70% {background: #001940;}
  85% {background: #001331;}
  100% {background: #000e23;}
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .modal-la-content .modal-la-header > h1 {
    font-size: 16px;
  }
  .modal-la-content {
    width: 300px;
    height: 330px;
  }
  .modal-la-button {
    font-size: 16px;
    width: 100px;
  }

  .modal-la-btns {
    gap: 20px;
  }

  .modal-la-content .modal-la-header {
    height: 180px;
  }
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .modal-la-content {
    width: 440px;
    height: 380px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  & Laptops, Desktops big screens
*/
@media (min-width: 768px) {
  .modal-la-content {
    width: 500px;
    padding: 10px 10px 0 10px;
  }
  .modal-la-content .modal-la-footer {
    width: 100%;
    padding: 10px;
  }
}
