.tournament {
  &-iframe-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: calc(100vh - 141px);
  }

  .iframe{
    width: 100%;
    height: 100%;
  }
}
