div.qr-modal-container {
  position: absolute;
  bottom: 15px;
  right: 0;
  left: 0;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

div.qr-modal-container .qrmodalicon {
  width: 45px;
  height: auto;
}

button.qrlogo {
  visibility: hidden;
  opacity: 0;
  color: white;
  transition: visibility 0.2s linear, color 0.2s ease-in-out;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 50px;
  border: none;
  border-radius: 5px;
  margin-top: 10px;
  z-index: 1;
  position: relative;
  cursor: pointer;
}

button.qrlogo:hover {
  transform: scale(1.1);
}
