.launcher {
  color: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;

  label {
    color: #1e88e5;
    margin-top: 5px;
  }

  label:nth-last-of-type(-n + 2) {
    margin-top: 15px;
  }

  input,
  select,
  div {
    color: rgba(255, 255, 255, 0.9);
  }

  input:-webkit-autofill,
  textarea:-webkit-autofill,
  select:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1200px #000E24 inset !important;
    -webkit-text-fill-color: white !important;
  }

  > form {
    min-width: 80%;
    min-height: 60%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 2px;
    border: 1px solid rgb(6, 68, 115);
    padding: 15px 20px;
    background: #000e24;
    border-radius: 8px;
  }

  .title {
    margin-top: 15px;
    color: #ffffffdf;
    font-size: 24px;
    font-weight: bold;
  }

  .btn {
    background-color: transparent;
    outline: none;
    height: 40px;
    font-size: 16px;
    margin-top: 10px;
    width: 120px;
    border: none;
    padding: 0.5rem 1rem;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
      rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
      rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 8px;
    text-decoration: none;
    background: #004ca4;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    transition: background 250ms ease-in-out, transform 150ms ease;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .btn:hover {
    font-weight: bold;
    color: rgb(4, 94, 167);
    background-color: rgba(255, 255, 255, 0.852);
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  .url-container {
    display: flex;
  }

  .label-padding {
    padding-right: 10px;
  }
  
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1024px) {
  .launcher {
    > form {
      min-width: unset;
      max-width: 900px;
      min-height: unset;
      /*max-height: 700px;*/
      width: 60%;
      select,
      input[type="text"] {
        min-width: unset;
        width: 90%;
        max-width: 650px;
      }
    }
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .launcher {
    font-size: 14px;

    .title {
      font-size: 1.3rem;
    }

    label,
    input {
      font-size: 14px;
      margin-top: 0;
    }

    label:nth-last-of-type(-n + 2) {
      margin-top: 5px;
    }

    div.MuiInputBase-root,
    div.MuiFormLabel-root {
      height: 40px;
      font-size: 14px;
    }

    > form {
      min-width: 70%;
      width: 90%;
      padding: 10px;
    }
  }
}
