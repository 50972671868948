.copy-token {
    &-icon {
      position: fixed;
      bottom: 24px;
      right: 24px;
      width: 36px;
      height: 36px;
      border-radius: 4px;
      border: 1px solid #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      font-size: 24px;
      transition: all 0.2s ease-in-out;
      z-index: 999;
      cursor: pointer;
    }
    &-icon:hover {
      transform: scale(1.1);
    }
  }
  