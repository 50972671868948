/* modal styles */
.modal-backdrop {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 40;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(20px);
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 290px;
  margin: auto;
  transition: all 0.2s ease-in-out;
  padding: 5px 5px 0 5px;
  background-color: white;
}

.modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  background-color: white;
  color: black;
  font-weight: bold;
}

.modal-content .modal-header > div {
  width: 30px;
  height: 30px;
}

.modal-content .modal-body {
  display: flex;
  justify-content: center;
  width: 100%;
  color: #666;
  background-color: white;
  padding: 15px 0 30px 0;
}

.modal-content .modal-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #161616;
  padding: 5px;
  font-size: 28px;
}

.modal-content .modal-footer > p {
  padding-right: 20px;
}

.modal-button {
  font-size: 26px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  outline: none;
  border: none;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}

.modal-button:hover {
  transform: scale(1.2);
  font-weight: bolder;
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px)
{
  .modal-content .modal-header > h1 {
    font-size: 16px;
  }
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
  .modal-content {
    width: 360px;
  }
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
  & Laptops, Desktops big screens
*/
@media (min-width: 768px) {
  .modal-content {
    width: 400px;
    padding: 10px 10px 0 10px;
  }
  .modal-content .modal-footer {
    width: 100%;
    padding: 10px;
  }
}
