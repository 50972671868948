/* modal-iframe styles */
.modal-iframe-backdrop {
  display: flex;
  justify-content: center;
  position: fixed;
  z-index: 40;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000e24;
}

.modal-iframe-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  transition: all 0.2s ease-in-out;
  background-color: transparent;
  border: 1px solid rgb(6, 68, 115);
  border-radius: 8px;
  padding: 1vw 2vw;
  width: 80vw;
  height: 90vh;
}

.modal-iframe-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 5%;
  background-color: transparent;
}

.modal-iframe-header > div {
  width: 1.7rem;
  height: 1.7rem;
}

.modal-iframe-body {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 100%;
  height: 85%;

  > .iframeDesktop {
    width: calc(61.1vh * 2);
    height: calc(30.55vh * 2);
  }
  > .iframeMobilePortrait {
    width: calc(30.55vh * 1.2);
    height: calc(61.1vh * 1.2);
    border: 1vh solid rgb(25, 25, 25);
    border-radius: 2.5vh;
  }
  > .iframeMobileLandscape {
    width: calc(61.1vh * 1.2);
    height: calc(30.55vh * 1.2);
    border: 1vh solid rgb(25, 25, 25);
    border-radius: 2.5vh;
  }
}

.modal-close-icon {
  font-size: 1.7rem;
  color: #ffffff;
  cursor: pointer;
  opacity: 0.7;
}

.modal-close-icon:hover {
  transform: scale(1.1);
  opacity: 1;
}

.modal-iframe-footer {
  width: 100%;
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.modal-iframe-footer img {
  padding: 10px;
}

.modal-iframe-desktop-icon,
.modal-iframe-portrait-icon,
.modal-iframe-mobile-icon {
  opacity: 0.4;
}

.modal-item-selected,
.modal-iframe-desktop-icon:hover,
.modal-iframe-portrait-icon:hover,
.modal-iframe-mobile-icon:hover {
  opacity: 1;
  transform: scale(1.2);
}

.modal-iframe-button:hover {
  transform: rotate(5deg);
  font-weight: bolder;
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
}

/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
}

@media (min-width: 320px) and (max-width: 1024px) {
  .modal-iframe-footer {
    display: none;
  }

  .modal-close-icon {
    font-size: 1.3rem;
  }

  .modal-iframe-content {
    height: 75vh;
    justify-content: center;
  }

  .iframeFullScreen {
    width: 100%;
    height: 100%;
  }
}
