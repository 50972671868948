.preview {
  -webkit-box-shadow: 0px 3px 26px -2px rgba(10, 10, 10, 0.71);
  box-shadow: 0px 3px 26px -2px rgba(10, 10, 10, 0.71);
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 180px;
  &-jackpot {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgb(120, 127, 139);
    color: rgb(0 14 35);
    font-weight: 700;
    height: 35px;
    font-size: 20px;
    border-radius: 8px;
  }
  &-isTagged {
    position: absolute;
  }
  &-image {
    height: 100%;
    max-width: 100%;
    width: 200px;
    object-fit: unset;
  }
  &-default-image{
    display: flex;
    align-items: center;
    text-align: center;
    line-height: 24px;
    height: 100%;
    width: 100%;
    color: white;
    font-size: 16px;
    font-weight: bold;
    flex-direction: column;
    justify-content:center;
    background: linear-gradient(171deg, #003282, #001e4e 100%);
  }
  &-selector {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.3s linear, opacity 0.3s linear;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgb(10, 22, 31);
    background: linear-gradient(
      0deg,
      rgba(10, 22, 31, 1) 0%,
      rgba(10, 22, 31, 0.96) 70%,
      rgba(10, 22, 31, 0.9) 80%,
      rgba(10, 22, 31, 0.86) 90%,
      rgba(10, 22, 31, 0.8) 100%
    );

    .title {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 5px;
      text-align: center;
      line-height: 24px;
    }

    .material-symbols-outlined {
      font-size: 36px;
    }

    button {
      outline: none;
      height: 40px;
      font-size: 16px;
      margin-top: 10px;
      width: 165px;
      border: none;
      box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em,
        rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
      border-radius: 4px;
      text-decoration: none;
      background: #004ca4;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      transition: background 250ms ease-in-out, transform 150ms ease;
      -webkit-appearance: none;
      -moz-appearance: none;
      z-index: 2;
      position: relative;
    }

    button:hover {
      background: #002a5a;
    }

    ul {
      padding-top: 10px;
      flex-wrap: wrap;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      gap: 35px;

      li {
        transition: all 0.2s ease-in-out;
        img {
          background-color: #004abb;
          border-radius: 5px;
          padding: 2px 4px;
          width: 38px;
        }
      }
      li:hover {
        transform: scale(1.1);
      }
    }
  }
}
.preview:hover {
  .preview-selector {
    visibility: visible;
    opacity: 1;
  }
  button.qrlogo {
    visibility: visible;
    opacity: 1;
  }
}
.preview-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .preview {
    &-selector {
      .title {
        margin-top: 5px;
      }
      ul {
        padding-top: 0px;
      }
    }
    div.qr-modal-container {
      bottom: 5px;
    }

    .title {
      font-size: 16px;
      margin-top: 30px;
    }

    button {
      width: 165px;
    }
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 480px) {
  .preview {
    border-radius: 16px;
    height: 272px;
    &-image {
      height: 100%;
      max-width: 100%;
      width: 280px;
      object-fit: cover;
    }
    &-default-image{
      font-size: 19px;
    }
    &-jackpot {
      height: 50px;
      font-size: 24px;
      border-radius: 16px;
      margin-top: 10px;
    }
    button {
      width: 75%;
      height: 55px;
      margin-top: 35px;
    }
  }
  .preview-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
}
