.game-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  flex-wrap: wrap;
  height: 80px;
  color: #ffffff;
  font-size: 18px;
  margin-bottom: -24px;
  &-config {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px 0 36px;
    height: 100%;
    &-select {
      width: 140px;
      height: 40px;
      font-weight: bolder;
      padding: 3px;
      border-radius: 5px;
    }
    &-div {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 15px;
      height: 100%;
    }
  }
  &-device {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 0 20px;
    flex-wrap: wrap;
    height: 100%;
  }
  //Comment to able currency field
  .currency-field {
    display: none;
  }
}

/*
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/
@media (min-width: 1281px) {
}
/*
  ##Device = Laptops, Desktops
  ##Screen = B/w 1025px to 1280px
*/
@media (min-width: 1025px) and (max-width: 1280px) {
}
/*
  ##Device = Tablets, Ipads (portrait)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) {
  .game-bar {
    &-config {
      justify-content: flex-end;
      padding: 0 10px 0 40px;
      &-select {
        width: 120px;
      }
      &-div {
        justify-content: flex-start;
        height: 50%;
      }
    }
    &-device {
      padding: 0 10px;
    }
  }
}
/*
  ##Device = Tablets, Ipads (landscape)
  ##Screen = B/w 768px to 1024px
*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}
/*
  ##Device = Low Resolution Tablets, Mobiles (Landscape)
  ##Screen = B/w 481px to 767px
*/
@media (min-width: 481px) and (max-width: 767px) {
}
/*
  ##Device = Most of the Smartphones Mobiles (Portrait)
  ##Screen = B/w 320px to 479px
*/
@media (min-width: 320px) and (max-width: 767px) {
  .game-bar {
    height: 160px;
    font-size: 16px;
    margin-top: 16px;
    &-config {
      justify-content: center;
      width: 100%;
      height: 50%;
      gap: 10px;
      padding: 0 10%;
      &-select {
        width: 120px;
      }
      &-div {
        height: 50%;
      }
    }
    &-device {
      justify-content: center;
      width: 100%;
      height: 50%;
    }
    &-config-field2 {
      padding: 0 0 0 20px;
    }
  }
}
